<template>
  <div class="login-container">
    <div style="position: fixed;bottom: 5px;color: rgba(255,255,255,0.6)">
      <a href="https://beian.miit.gov.cn/" target="_blank"
         style="font-size: 12px;color: rgba(255,255,255,0.6);margin-right: 15px">蜀ICP备2022025016号-1</a>
      禁止发布、传播任何违法、违规内容，使用本网站，视您接受并同意
      <a href="https://docs.qq.com/doc/p/8f74cd62707b68ef4043ad9a643f905c4b7906c7" target="_blank"
         style="font-size: 13px;font-weight: bold;color: #2977ff">
        《网站免责声明》
      </a>


    </div>
    <div class="title">
      <span style="font-weight: bold;font-size: 60px">HMY</span>
      <br>
      <span>的测试项目</span>
      <br>
      <div style="font-size: 35px">IM即时通信</div>
      <div style="font-size: 30px">即时互动，尽情畅聊</div>
      <div style="font-size: 15px;margin-top: 15px"><span style="margin-right: 10px">测试账号：test</span><span>密码：123456</span></div>

    </div>
    <div class="login-card">
      <div class="card-container">
        <h1 class="login-title">欢迎登录</h1>
        <form class="login-form">
          <div class="form-group">
            <span class="label">用户名</span>
            <input type="text" placeholder="请输入用户名" autocomplete="off" v-model="form.name" class="form-input">
          </div>
          <div class="form-group">
            <span class="label">密码</span>
            <input type="password" placeholder="请输入密码" v-model="form.password" class="form-input">
          </div>
          <div style="font-size: 13px">
            <input type="checkbox" v-model="checked" class="form-checkbox">我已阅读并同意
            <a href="https://docs.qq.com/doc/p/8f74cd62707b68ef4043ad9a643f905c4b7906c7" target="_blank"
               style="font-size: 13px;font-weight: bold;color: #0060ff">
              《网站免责声明》
            </a>
          </div>

          <button class="login-button" type="button" @click="login">登录</button>
        </form>
        <div class="login-links">
          <a @click="forgotPassword" class="forgot-password-link">忘记密码</a>
          <a @click="toRegister" class="to-register-link">没有账号，立即注册</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        form: {
          name: '',
          password: '',
        },
        checked:false
      }
    },
    created() {
    },
    methods: {
      login() {
        if (this.form.name.trim() === '') {
          console.log('请输入用户名');
          this.$message({
            showClose: true,
            message: '请输入用户名',
            type: 'error'
          });
        } else if (this.form.password.trim() === '') {
          console.log('请输入密码');
          this.$message({
            showClose: true,
            message: '请输入密码',
            type: 'error'
          });
        } else {
          if (this.checked===false){
            this.$message({
              showClose: true,
              message: '请阅读并同意《网站免责声明》',
              type: 'error'
            });
            return;
          }
          this.$http.post('/user/login', this.form).then((res) => {
            if (res.data) {
              let user = res.data;
              this.globalData.currentUser = user;
              this.$router.replace({path: './conversations'});
              localStorage.setItem("user_save", JSON.stringify(user));
            } else {
              console.log("账号或密码错误");
              this.$message({
                showClose: true,
                message: "账号或密码错误",
                type: 'error'
              });
            }
          })
        }
      },
      forgotPassword() {
        this.$message({
          message: '你真的忘记密码了吗？',
          type: 'warning'
        });
      },
      toRegister() {
        this.$router.push({path: './register'});
      }
    }
  }
</script>

<style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(../assets/images/background1.jpg);
    background-repeat: no-repeat;
    background-size: cover;

  }

  .title {
    color: #37474F;
    font-size: 60px;
    font-family: "Microsoft YaHei";
    margin-right: 20%;
    margin-bottom: 100px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.10);
  }

  .login-card {
    width: 300px;
    background-color: rgba(255, 255, 255, 0.30);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    padding: 40px;
    border-radius: 15px;
    backdrop-filter: blur(7px);
  }

  .login-title {
    font-size: 35px;
    font-weight: bold;
    color: #37474F;
    margin-bottom: 40px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);

    border-radius: 8px;
    padding: 5px 12px;
  }

  .label {
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
    width: 70px;
  }

  .form-input {
    padding: 10px;
    border: none;
    font-size: 16px;
    color: #37474F;
    flex: 1;
    /*margin-left: 5px;*/
    background: rgba(255, 255, 255, 0);
  }

  .form-input::placeholder {
    font-size: 15px;
    color: #37474F;
  }

  .form-input:focus {
    outline: none;
  }

  .form-checkbox{
    opacity: 0.8;
  }


  .login-button {
    background-color: #2979FF;
    color: #FFFFFF;
    border: none;
    padding: 12px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 8px;
  }

  .login-button:hover {
    background-color: #1E88E5;
  }

  .login-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .forgot-password-link,
  .to-register-link {
    font-size: 16px;
    color: black;
    text-decoration: none;
  }

  .forgot-password-link:hover,
  .to-register-link:hover {
    color: #0060ff;
    cursor: pointer;
  }


  @media screen and (max-width: 1000px) {
    .title {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    .title {
      display: none;
    }

    .card-container {
      width: 100%;
    }

    .login-card {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 0;
      display: flex;
      align-items: center;
    }
  }
</style>
